import { Autocomplete, TextField, createFilterOptions } from '@mui/material';

import { TableI } from 'types/project';

import useStyles from './style';

export interface SearchOptionType {
  key: string;
  title: string;
  type: string;
}

interface DataTableProps {
  table: TableI;
  filters: SearchOptionType[];
  setFilters: (filters: SearchOptionType[]) => void;
}

const filter = createFilterOptions<SearchOptionType>();

const Search = (props: DataTableProps) => {
  const classes = useStyles();
  const searchOptions: SearchOptionType[] = [];
  const statusFilter = props.filters.find(({ type }) => type === 'status');
  const columns = props.table?.columns.filter((column) => !props.filters.find(({ type }) => type === column.key));

  if (!statusFilter) {
    props.table?.statuses?.forEach((status) => {
      // TODO 'done' asn 'status' in constans
      if (status?.id !== 'done') {
        searchOptions.push({ key: status?.id, title: `Статус: ${status?.name}`, type: 'status' } as SearchOptionType);
      }
    });
  }

  columns.filter(({ type }) => type?.includes('member')).forEach((column) => {
    const type = column.type?.split(':')[1];

    if (type) {
      props.table?.members.forEach((member) => {
        if (member.role === type) {
          searchOptions.push({
            key: member?.id,
            title: `${props.table?.roles[member.role].name}: ${member.name}`,
            type: column.key,
          } as SearchOptionType);
        }
      });
    }
  });

  return (
    <Autocomplete
      multiple
      freeSolo
      className={classes.search}
      options={searchOptions}
      getOptionLabel={(option) => (option as SearchOptionType)?.title}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Пошук"
          placeholder="Для пошуку по назві почніть вводити значення"
        />
      )}
      onChange={(_event, newValue) => {
        props.setFilters(newValue as SearchOptionType[]);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.title);

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            key: inputValue,
            title: `Пошук: ${inputValue}`,
            type: 'name', // TODO move to constans
          });
        }

        return filtered;
      }}
    />
  );
}

export default Search;