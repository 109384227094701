import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  ButtonGroup,
  FormGroup,
  ModalProps,
  TextField,
  Typography,
} from '@mui/material';

import Modal from 'components/Modal';
import { useAppDispatch } from 'hooks/store';
import { authLogin } from 'store/user';

import useStyles from './style';

export default function AuthModal(props: ModalProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      login: '',
      pass: '',
    },
    validationSchema: Yup.object({
      login: Yup.string().email().required('Required'),
      pass: Yup.string().required('Required'),
    }),
    onSubmit: ({ login, pass }) => {
      dispatch(authLogin({
        login,
        pass,
      }));
    },
  });

  return (
    <Modal className={props.className} open>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" align="center" className={classes.modalTitle}>
          Авторизація
        </Typography>
        <FormGroup>
          <TextField
            id='login'
            name='login'
            value={formik.values['login']}
            onChange={formik.handleChange}
            error={formik.touched['login'] && Boolean(formik.errors['login'])}
            helperText={formik.touched['login'] && formik.errors['login']}
            className={classes.inputField}
            label="Логін"
            variant="outlined"
            size="small"
          />
          <TextField
            id='pass'
            name='pass'
            value={formik.values['pass']}
            onChange={formik.handleChange}
            error={formik.touched['pass'] && Boolean(formik.errors['pass'])}
            helperText={formik.touched['pass'] && formik.errors['pass']}
            className={classes.inputField}
            label="Пароль"
            variant="outlined"
            size="small"
          />
          <ButtonGroup orientation="vertical">
            <Button variant="contained" className={classes.submitButton} type="submit">
              Авторизуватись
            </Button>
            <Button
              color="secondary"
              variant="contained"
              href={`${process.env.REACT_APP_SERVER}/auth/google`}
            >
              Авторизуватись через Google
            </Button>
          </ButtonGroup>
        </FormGroup>
      </form>
    </Modal>
  );
}
