import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, TextField, FormGroup, Box } from "@mui/material";

import Layout from "components/Layout";
import { useAppDispatch } from "hooks/store";
import { createProject } from "store/projects";

const validationSchema = Yup.object({
  name: Yup.string().min(3).max(30).required("Required"),
});

function CreateProject() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ name }) => {
      const result = await dispatch(
        createProject({
          name,
          layout: "TASKS_MANAGEMENT",
        })
      );
      const data = unwrapResult(result);
      navigate(`/panel/projects/${data.id}`);
    },
  });

  return (
    <Layout title="Створення проєкту">
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Box marginBottom={5}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Назва"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <Button variant="contained" type="submit">
            Створити
          </Button>
        </FormGroup>
      </form>
    </Layout>
  );
}

export default CreateProject;
