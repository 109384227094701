import Table from 'components/Table';
import { TableActionI, TableI } from 'types/project';

interface DataTableProps {
  data: Record<string, string>[];
  table: TableI;
  setSelectedRow: (row: Record<string, string>) => void;
  handleDeleteModalOpen: () => void;
}

const COLUMNS = [
  {
    key: `name`,
    name: `Користувач`,
    show: true,
  },
  {
    key: `email`,
    name: `Пошта`,
    show: true,
  },
  {
    key: `role`,
    type: `role`,
    name: `Роль`,
    show: true,
  },
];

const DataTable = (props: DataTableProps) => {
  const actions: TableActionI[] = [
    {
      name: 'Видалити',
      color: 'error',
      filter: (row: Record<string, string>) => row.role !== 'owner',
      handler: (row: Record<string, string>) => {
        props.setSelectedRow(row);
        props.handleDeleteModalOpen();
      },
    },
  ];

  return <Table columns={COLUMNS} table={props.table} rows={props.data} actions={actions} />;
};

export default DataTable;
