import { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import Layout from "components/Layout";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { UserI, getProfile, selectUser } from "store/user";

function Account() {
  const dispatch = useAppDispatch();
  const user: UserI | null = useAppSelector<UserI | null>(selectUser);

  const handleStartButtonClick = () => {
    if (user) {
      const deepLink = `https://t.me/HellTaskBot?start=${user.id}`;

      window.open(deepLink, '_blank');
    }
  };

  useEffect(() => {
    if (!user) {
      dispatch(getProfile());
    }
  }, [dispatch, user]);

  return (
    <Layout title="Налаштування акаунта">
      {
        !(user?.telegramID)
          ? <Button variant="contained" onClick={handleStartButtonClick}>
            Підключити телеграм
          </Button>
          : <Typography variant="body1">Нотіфікації телеграм включені</Typography>
      }
    </Layout>
  );
}

export default Account;
