import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundImage:
      'linear-gradient(to right bottom, #ffffff, #dad6ef, #b4afdf, #8d89cf, #6365be, #4a52aa, #2e4197, #003084, #002869, #00204e, #001734, #00091b)',
  },
  modal: {
    '& .MuiModal-backdrop': {
      background: 'none',
    },
  },
});
