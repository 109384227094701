import * as Yup from 'yup';
import { Button, Box, TextField, FormGroup, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import Modal from 'components/Modal';
import { useFormik } from 'formik';
import { useAppDispatch } from 'hooks/store';
import { addMembersByTableId } from 'store/projects';
import { RoleI } from 'types/project';
import useStyles from './style';

interface AddMemberModalProps {
  tableID: string;
  projectID: string;
  open: boolean;
  members: Record<string, string>[];
  roles: Record<string, RoleI>;
  handleClose: () => void;
}

function AddMemberModal(props: AddMemberModalProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Required'),
      role: Yup.string().required('Required'),
    }),
    onSubmit: async ({ email, role }) => {
      if (props.members.find((member) => member.email === email)) {
        formik.setErrors({ email: 'Користувач з такою поштою вже доданий' });
      } else {
        await dispatch(
          addMembersByTableId({
            email,
            role,
            tableID: props.tableID,
            projectID: props.projectID,
          }),
        );
        props.handleClose();
      }
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      title="Додати користувача"
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Box marginBottom={1}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Пошта"
                variant="standard"
                value={formik.values['email']}
                onChange={formik.handleChange}
                error={
                  formik.touched['email'] && Boolean(formik.errors['email'])
                }
                helperText={formik.touched['email'] && formik.errors['email']}
              />

              <InputLabel id='role' error={formik.touched['role'] && Boolean(formik.errors['role'])}>
                Роль
              </InputLabel>
              <Select
                fullWidth
                labelId='role'
                id='role'
                variant="standard"
                value={formik.values['role'] || 'none'}
                label="Роль"
                onChange={(e) => formik.setFieldValue('role', e.target.value)}
                error={formik.touched['role'] && Boolean(formik.errors['role'])}
              >
                {!formik.values['role'] && <MenuItem value='none'>Не вибрано</MenuItem>}
                {Object.keys(props.roles).filter((role) => role !== 'owner')?.map((role) => (
                  <MenuItem key={role} value={role}>{props.roles[role]?.name}</MenuItem>
                ))}
              </Select>
              {formik.touched['role'] && formik.errors['role'] && (
                <FormHelperText error>{formik.errors['role']}</FormHelperText>
              )}
            </Box>
            <Box className={classes.buttonGroup}>
              <Button
                className={classes.buttonAdd}
                variant="outlined"
                type="submit"
              >
                Додати
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={props.handleClose}
              >
                Закрити
              </Button>
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Modal>
  );
}

export default AddMemberModal;
