// TODO refacting this component
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import People from '@mui/icons-material/People';
import Dns from '@mui/icons-material/Dns';
import Add from '@mui/icons-material/Add';
import AssignmentInd from '@mui/icons-material/AssignmentInd';

import { getProjects, selectProjects } from 'store/projects';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { UserI, getProfile, selectUser } from 'store/user';

import useStyles from './style';
import { Settings } from '@mui/icons-material';

const SideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const user = useAppSelector<UserI | null>(selectUser);
  const [open, setOpen] = React.useState<string | null>(null);
  
  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      dispatch(getProfile());
    }
  }, [dispatch, user]);

  return (
    <div className={classes.sidebar}>
      <Box sx={{ display: 'flex' }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}
      >
        <Paper elevation={0} sx={{ width: '100%' }}>
          <List component="nav" disablePadding>
            {projects?.length === 0 ? <ListItemButton onClick={() => navigate('/panel/projects/create')}>
              <ListItemIcon sx={{ fontSize: 20 }}>
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <Add />
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary="Створити проєкт"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
            </ListItemButton> : null}
            <Divider />

            {projects.map(project => (
              <Box
                key={project.id}
                sx={{
                  bgcolor: open === project.id ? 'rgba(71, 98, 130, 0.2)' : null,
                  pb: open === project.id ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => {
                    setOpen(open === project.id ? null : project.id);
                    navigate(`/panel/projects/${project.id}`);
                  }}
                  sx={{
                    px: 3,
                    pt: 2,
                    pb: 2,
                    '& svg': { opacity: 1 }
                  }}
                >
                  <ListItemText
                    primary={project.name}
                    primaryTypographyProps={{
                      fontSize: 18,
                      fontWeight: 'medium',
                      lineHeight: '20px',
                    }}
                    sx={{ my: 0 }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: open === project.id ? 'rotate(-180deg)' : 'rotate(0)',
                      transition: '0.2s',
                    }}
                  />
                </ListItemButton>
                {open === project.id &&
                  <>
                    <ListItemButton
                      key='Дані'
                      sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)', mt: 0.5 }}
                      onClick={() => navigate(`/panel/projects/${project.id}`)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <Dns />
                      </ListItemIcon>
                      <ListItemText
                        primary='Дані'
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>

                    {project?.ownerID === user?.id && <ListItemButton
                      key='Команда'
                      sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)', mt: 0.5 }}
                      onClick={() => navigate(`/panel/members/${project.id}`)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <People />
                      </ListItemIcon>
                      <ListItemText
                        primary='Команда'
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>}

                    {project?.ownerID === user?.id && <ListItemButton
                      key='Замовники'
                      sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)', mt: 0.5 }}
                      onClick={() => navigate(`/panel/customers/${project.id}`)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <AssignmentInd />
                      </ListItemIcon>
                      <ListItemText
                        primary='Замовники'
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>}

                    {project?.ownerID === user?.id && <ListItemButton
                      key='Завершені'
                      sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)', mt: 0.5 }}
                      onClick={() => navigate(`/panel/archive/${project.id}`)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary='Завершені'
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>}

                    {project?.ownerID === user?.id && <ListItemButton
                      key='Налаштування'
                      sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)', mt: 0.5 }}
                      onClick={() => navigate(`/panel/projects-settings/${project.id}`)}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText
                        primary='Налаштування'
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      />
                    </ListItemButton>}
                  </>
                }
              </Box>
            ))}
          </List>
        </Paper>
      </ThemeProvider>
    </Box>
    </div>
  );
}

export default SideBar;
