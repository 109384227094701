import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Button, Box, FormGroup } from '@mui/material';

import { addDataByTableId, editDataByTableIdAndRowId, deleteDataByTableIdAndRowId } from 'store/data';
import { useAppDispatch } from 'hooks/store';
import { ColumnI, TableI } from 'types/project';
import Modal from 'components/Modal';

import FormField from '../FormField';
import useStyles from './style';

interface EditRowModalProps {
  table: TableI;
  columns: ColumnI[];
  members?: Record<string, string>[];
  statuses?: Record<string, string>[];
  open: boolean;
  handleClose: () => void;
  forCreate?: boolean;
  data?: Record<string, any>;
};

dayjs.extend(utc);

function EditRowModal(props: EditRowModalProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const validation: Yup.ObjectShape = {};
  const initialValues: Record<string, string> = {};
  const columns = props.columns.filter(({ show }) => show);

  columns.forEach(({ key, type, init }) => {
    validation[key] = Yup.string().required('Required');

    if (typeof props.data?.[key] === 'object') {
      initialValues[key] = props.data?.[key]?.id;
    } else if (type === 'date') {
      initialValues[key] =  dayjs(props.data?.[key]).format('YYYY-MM-DD HH:mm:ss');
    } else {
      initialValues[key] =  props.data?.[key] || '';
    }
    
    if (initialValues[key] === '' && init) {
      if (type === 'date') {
        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);

        // TODO move format to constants
        initialValues[key] = init === 'tomorrow' 
          ? dayjs(tomorrowDate).format('YYYY-MM-DD HH:mm:ss')
          : dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
      }
    }
  });

  const validationSchema = Yup.object(validation);
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: Record<string, string>) => {
      const valuesUpdated = values;

      columns.forEach((column) => {
        if (column.type=== 'date') {
          valuesUpdated[column.key] = dayjs(valuesUpdated[column.key])?.utc().format('YYYY-MM-DD HH:mm:ss') || valuesUpdated.value;
        }
      });
      if (props.forCreate) {
        await dispatch(addDataByTableId({
          ...valuesUpdated,
          tableID: props.table?.id,
        }));
      } else {
        await dispatch(editDataByTableIdAndRowId({
          ...valuesUpdated,
          tableID: props.table?.id,
          rowID: props.data?.id,
        }));
      }
      props.handleClose();
    },
  });

  const handleDelete = async () => {
      await dispatch(deleteDataByTableIdAndRowId({
        tableID: props.table?.id,
        rowID: props.data?.id,
      }));
      props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      title={props.forCreate ? 'Додати запис' : 'Редагувати запис'}
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            {columns.map((column) => (
              <Box key={column.key} marginBottom={1}>
                <FormField formik={formik} column={column} members={props.table.members} customers={props.table.customers} statuses={props.table.statuses} />
              </Box>
            ))}
            <Box className={classes.buttonGroup}>
              {!props.forCreate && (
                <Button className={classes.buttonDelete} color="error" variant="outlined" onClick={handleDelete}>
                  Видалити
                </Button>
              )}
              <Button className={classes.buttonAdd} variant="outlined" type="submit">
                {props.forCreate ? 'Додати' : 'Зберегти'}
              </Button>
              <Button variant="outlined" color="error" onClick={props.handleClose}>
                Закрити
              </Button>
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Modal>
  );
}

export default EditRowModal;