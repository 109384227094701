import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import lodash from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks/store';
import { getProjectDetails, selectProjectDetails } from 'store/projects';
import {
  ColumnI,
  ProjectsDetailsI,
  RoleI,
  TableActionI,
  TableI,
} from 'types/project';
import { UserI, getProfile, selectUser } from 'store/user';
import { nextStatusByTableIdAndRowId, prevStatusByTableIdAndRowId } from 'store/data';

import Layout from 'components/Layout';
import DataTable from './DataTable';
import AddRowModal from './EditRowModal';
import EditRowModal from './EditRowModal';
import Search, { SearchOptionType } from 'components/Search';

import useStyles from './style';

function Project() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = useState<Record<string, string> | null>(
    null,
  );
  const [filters, setFilters] = useState<SearchOptionType[]>([]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleCreateModalOpen = () => setCreateModalOpen(true);
  const handleCreateModalClose = () => setCreateModalOpen(false);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const projectDetails: ProjectsDetailsI | null =
    useAppSelector(selectProjectDetails);
  const user: UserI | null = useAppSelector(selectUser);
  const table: TableI | undefined = projectDetails?.tables[0];
  const columns: ColumnI[] | undefined = table?.columns;
  const roles: Record<string, RoleI> | undefined = table?.roles;
  const statuses: Record<string, string>[] | undefined = table?.statuses;
  const member: Record<string, string> | undefined = user
    ? table?.members.find((member) => member.id === (user as UserI).id)
    : undefined;
  const accesses = member?.role && roles?.[member?.role]?.accesses;
  const canAdd = accesses?.includes('create') || false;
  const canEdit = accesses?.includes('edit') || false;
  const changeStatusActions: TableActionI[] = [];
  statuses?.forEach((status) => {
    if (status.prev) {
      changeStatusActions.push({
        name: status.prevStepAction,
        color: 'primary',
        filter: (row) => {
          const statusId = lodash.get(row.status, 'id') || '';
          if (status.id !== statusId || !status.prev) {
            return false;
          }
          return accesses?.includes(`status:${statusId}`) || false;
        },
        handler: (row) => {
          dispatch(
            prevStatusByTableIdAndRowId({ tableID: table?.id, rowID: row.id }),
          );
        },
      })
    }
    if (status.next) {
      changeStatusActions.push({
        name: status.nextStepAction,
        color: 'primary',
        filter: (row) => {
          const statusId = lodash.get(row.status, 'id') || '';
          if (status.id !== statusId || !status.next) {
            return false;
          }
          return accesses?.includes(`status:${statusId}`) || false;
        },
        handler: (row) => {
          dispatch(
            nextStatusByTableIdAndRowId({ tableID: table?.id, rowID: row.id }),
          );
        },
      })
    }
  });
  const actions: TableActionI[] = [
    {
      name: 'Редагувати',
      color: 'primary',
      filter: () => canEdit,
      handler: (row) => {
        setSelectedRow(row);
        handleEditModalOpen();
      },
    },
    ...changeStatusActions,
  ];

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!user) {
      dispatch(getProfile());
    }
  }, [dispatch, user]);

  return (
    <>
      <Layout title={`Проєкт ${projectDetails?.name}`}>
        <Box className={classes.tablePanel}>
          {table && <Search table={table} filters={filters} setFilters={setFilters} />}
          {canAdd && (
            <Button
              variant="contained"
              onClick={handleCreateModalOpen}
              startIcon={<AddIcon />}
            >
              Додати
            </Button>
          )}
        </Box>
        {table && (
          <DataTable
            table={table}
            actions={actions?.length ? actions : undefined}
            filters={filters}
          />
        )}
      </Layout>
      {columns && table?.id && createModalOpen ? (
        <AddRowModal
          open={createModalOpen}
          table={table}
          columns={columns}
          members={table?.members}
          statuses={statuses}
          handleClose={handleCreateModalClose}
          forCreate
        />
      ) : null}
      {table && selectedRow && columns && editModalOpen && (
        <EditRowModal
          open={editModalOpen}
          table={table}
          columns={columns}
          data={selectedRow}
          handleClose={handleEditModalClose}
        />
      )}
    </>
  );
}

export default Project;
