import * as Yup from 'yup';
import { Button, Box, TextField, FormGroup } from '@mui/material';
import Modal from 'components/Modal';
import { useFormik } from 'formik';
import { useAppDispatch } from 'hooks/store';
import { renameProjectById } from 'store/projects';
import useStyles from './style';

interface EditProjectModalProps {
  projectID: string;
  open: boolean;
  handleClose: () => void;
};

function EditProjectModal(props: EditProjectModalProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3).max(30).required("Required"),
    }),
    onSubmit: async ({ name }) => {
      try {
        await dispatch(renameProjectById({
          name,
          projectID: props.projectID,
        }));
        props.handleClose();
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      title='Перейменувати проєкт'
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Box marginBottom={1}>
            <TextField
              fullWidth
              id='name'
              name='name'
              label='Назва проєкту'
              variant="standard"
              value={formik.values['name']}
              onChange={formik.handleChange}
              error={formik.touched['name'] && Boolean(formik.errors['name'])}
              helperText={formik.touched['name'] && formik.errors['name']}
            />
            </Box>
            <Box className={classes.buttonGroup}>
              <Button variant="outlined" type="submit">
                Змінити
              </Button>
              <Button variant="outlined" color="error" onClick={props.handleClose}>
                Закрити
              </Button>
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Modal>
  );
}

export default EditProjectModal;
