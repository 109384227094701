import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { useAppDispatch } from 'hooks/store';
import { logout } from 'store/user';
import useStyles from './style';

const Header = () => {
  const navigate = useNavigate();
  const classes = useStyles()
  const dispatch = useAppDispatch();
  
  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <header className={classes.root}>
      <Box className={classes.logo}>
        <span className={classes.bigLetter}>H</span>ell<span className={classes.bigLetter}>T</span>ask
        <span className={classes.version}> v0.1</span>
      </Box>
      <Box>
        <Button variant="outlined" onClick={() => navigate('/panel')}>
          Налаштування
        </Button>
        <Button variant="contained" onClick={logoutHandler}>
          Вийти
        </Button>
      </Box>
    </header>
  );
}

export default Header;