import {createUseStyles} from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '60px',
    maxWidth: '1000px',
    width: '80%',
    padding: '0 50px',
    margin: '0 auto',
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 4px 4px white'
  },
  logo: {
    fontSize: '22px',
    fontWeight: '800',
  },
  bigLetter: {
    color: 'rgb(25, 118, 210)'
  },
  version: {
    fontSize: '12px',
    fontWeight: '400',
  },
})