import { useEffect } from 'react';
import Table from 'components/Table';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { DataI, getArchiveByTableId, selectArchive } from 'store/archive';
import { ColumnI, TableI } from 'types/project';

interface ArchiveTableProps {
  table: TableI;
}

const ArchiveTable = (props: ArchiveTableProps) => {
  const dispatch = useAppDispatch();
  const data: Record<string, DataI[]> = useAppSelector(selectArchive);
  const columns = props.table.columns.filter(({ show }: ColumnI) => show) || [];
  const tableId = props.table?.id;
  const rows = data?.[tableId] ? data?.[tableId]
    .map((row: Record<string, string>) => {
      const status = props.table.statuses.find((status: Record<string, string>) => status.id === row.status);
      return { ...row, status: status || row.status };
    }) : [];

  useEffect(() => {
    dispatch(getArchiveByTableId(props.table.id));
  }, [dispatch, props.table.id]);

  return (
    <Table
      columns={columns}
      rows={rows}
      table={props.table}
    />
  );
}

export default ArchiveTable;