import { Button, TableCell } from '@mui/material';
import { TableActionI } from 'types/project';
import useStyles from './style';
import { Box } from '@mui/system';

interface TableActionsProps {
  actions: TableActionI[];
  row: Record<string, string>;
}

const TableActions = (props: TableActionsProps) => {
  const classes = useStyles();
  const actions = props.actions.filter((action) => action.filter ? action.filter(props.row) : true);

  return (
    <TableCell className={classes.root}>
      <Box className={classes.actions}>
        {actions.map((action) => (
          <Button
            key={action.name}
            variant="contained"
            color={action.color || 'inherit'}
            onClick={() => action.handler(props.row)}
          >
            {action.name}
          </Button>
        ))}
      </Box>
    </TableCell>
  );
}

export default TableActions;