import {createUseStyles} from 'react-jss';

export default createUseStyles({
  buttonGroup: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  buttonDelete: {
    marginRight: "auto", 
  },
  buttonAdd: {
    marginRight: "5px", 
  },
})
