import {createUseStyles} from 'react-jss';

export default createUseStyles({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    width: '20%',
    marginTop: '20px',
    marginBottom: '20px'
  },
})