import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';
import axios from 'axios';
import { catchError } from './common';

export type DataI = Record<string, string>;

const initialState: Record<string, DataI[]> = {};

export const getArchiveByTableId = createAsyncThunk(
  'request/getArchiveByTableId',
  async (id: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/data/archive/${id}`, {
      withCredentials: true,
    }).catch(catchError);;

    return data;
  }
);

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getArchiveByTableId.fulfilled, (state, action) => {
        return action.payload
      })
  },
});

export const selectArchive = (state: RootState) => state.archive;

export default dataSlice.reducer;
