import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, Box } from '@mui/material';

import { useAppDispatch } from 'hooks/store';
import { deleteProjectById } from 'store/projects';
import ConfirmModal from 'components/ConfirmModal';
import Layout from "components/Layout";
import EditProjectModal from "./EditProjectModal";
import useStyles from './style';


function ProjectSettings() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const handleDelete = async () => {
    await dispatch(
      deleteProjectById({ projectID: id }),
    );
    navigate(`/panel`);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/projects/${id}`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setProject(data);
      })
      .catch(() => {});
  }, [id]);

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const handleRenameModalOpen = () => setRenameModalOpen(true);
  const handleRenameModalClose = () => setRenameModalOpen(false);

  return (
    <>
      <Layout title={`Налаштування проєкта ${project?.name}`}>
        <Box className={classes.buttonGroup}>
          <Button
            variant="contained"
            onClick={handleRenameModalOpen}
          >
            Перейменувати проєкт
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleDeleteModalOpen}
          >
            Видалити проєкт
          </Button>
        </Box>
      </Layout>
      <ConfirmModal
        open={deleteModalOpen}
        title="Чи впевнені ви що хочете видалити проєкт? Всі дані проєкту будуть видаленні"
        handleClose={handleDeleteModalClose}
        handleConfirm={handleDelete}
      />
      {renameModalOpen ? (
        <EditProjectModal
          open={renameModalOpen}
          projectID={id}
          handleClose={handleRenameModalClose}
        />
      ) : null}
    </>
  );
}

export default ProjectSettings;
