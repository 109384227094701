import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import useStyles from './style';

interface ModalProps {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmModal = (props: ModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={props.open} onClose={props.handleClose} title={props.title}>
      <Box className={classes.buttonGroup}>
        <Button
          className={classes.buttonSuccess}
          variant="outlined"
          onClick={props.handleConfirm}
        >
          Підтвердити
        </Button>
        <Button variant="outlined" color="error" onClick={props.handleClose}>
          Закрити
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
