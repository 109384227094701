import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';
import axios from 'axios';
import { catchError } from './common';

export interface UserI {
  id: string;
  name: string;
  email: string;
  level: string;
  status: string;
  token: string;
  telegramID: string;
  createdAt: string;
  updatedAt: string;
}

const initialState: UserI | null = null;

export const getProfile = createAsyncThunk(
  'request/getProfile',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER}/profile`, {
        withCredentials: true,
      }).catch(catchError);
      return response.data[0];
  }
);

export const authLogin = createAsyncThunk(
  'request/authLogin',
  async ({ login, pass }: { login: string, pass: string }) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER}/auth/login`,
      { username: login, password: pass },
      { withCredentials: true }
    ).catch(catchError).then(() => window.location.href = `${process.env.REACT_APP_ORIGIN}/panel`);
  }
);

export const logout = createAsyncThunk(
  'request/logout',
  async () => {
    return await axios.get(`${process.env.REACT_APP_SERVER}/logout`, { withCredentials: true }).catch(catchError);
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (_state, action) => {
        return action.payload;
      })
  },
});

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;


