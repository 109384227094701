import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import useStyles from './style';

interface LayoutProps {
  title: string;
  children: ReactNode | ReactNode[];
}

const Layout = ({ title, children }: LayoutProps) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography variant="h5" className={classes.title}>{title}</Typography>
      { children }
    </div>
  );
}

export default Layout;
