import { Backdrop, CircularProgress } from "@mui/material";
import { useAppSelector } from "hooks/store";
import { selectCommon } from "store/common";

const Loading = () => {
  const { loading } = useAppSelector(selectCommon);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      open={Boolean(loading)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;