import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  modalTitle: {
    marginBottom: '20px',
  },

  inputField: {
    marginBottom: '10px',
  },

  submitButton: {
    marginBottom: '10px',
  },
});

export default useStyles;
