import { Box } from '@mui/material';
import AuthModal from 'components/AuthModal';
import useStyles from './style';

function Login() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <AuthModal className={classes.modal} />
    </Box>
  );
}

export default Login;
