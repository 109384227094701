import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  deleteCustomerByTableIdAndCustomerId,
  getProjectDetails,
  selectProjectDetails,
} from 'store/projects';
import DataTable from './DataTable';
import AddCustomerModal from './AddCustomerModal';
import ConfirmModal from 'components/ConfirmModal';
import Layout from 'components/Layout';

function Customers() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const projectDetails = useAppSelector(selectProjectDetails);
  const customers = projectDetails?.tables[0]?.customers || [];
  const tableID = projectDetails?.tables[0].id;

  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const handleAddCustomerModalOpen = () => setAddCustomerModalOpen(true);
  const handleAddCustomerModalClose = () => setAddCustomerModalOpen(false);

  const [selectedRow, setSelectedRow] = useState<Record<string, string> | null>(
    null,
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const handleDelete = () => {
    if (tableID && selectedRow?.user && id) {
      dispatch(
        deleteCustomerByTableIdAndCustomerId({
          tableID,
          customerID: selectedRow?.user,
          projectID: id,
        }),
      );
      handleDeleteModalClose();
    }
  };

  useEffect(() => {
    dispatch(getProjectDetails(id as string));
  }, [dispatch, id]);

  return (
    <>
      <Layout title={`Проєкт ${projectDetails?.name}`}>
        <Button
          variant="contained"
          onClick={handleAddCustomerModalOpen}
          endIcon={<AddIcon />}
        >
          Додати
        </Button>

        {projectDetails?.tables?.[0]?.customers && (
          <DataTable
            data={customers}
            setSelectedRow={setSelectedRow}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        )}
      </Layout>
      {projectDetails?.tables[0].id && addCustomerModalOpen ? (
        <AddCustomerModal
          open={addCustomerModalOpen}
          tableID={projectDetails?.tables[0].id}
          projectID={projectDetails?.id}
          handleClose={handleAddCustomerModalClose}
        />
      ) : null}
      {deleteModalOpen && (
        <ConfirmModal
          open={deleteModalOpen}
          title="Чи впевнені ви що хочете видалити замовника?"
          handleClose={handleDeleteModalClose}
          handleConfirm={handleDelete}
        />
      )}
    </>
  );
}

export default Customers;
