import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';
import axios from 'axios';
import { catchError } from './common';

export type DataI = Record<string, string>;

export interface ProjectI {
  data: Record<string, DataI[]>;
  loading: Record<string, boolean>;
}

const initialState: ProjectI = {
  data: {},
  loading: {}
};

export const getDataByTableId = createAsyncThunk(
  'request/getDataByTableId',
  async (id: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER}/data/${id}`, {
      withCredentials: true,
    }).catch(catchError);;

    return data;
  }
);

export const addDataByTableId = createAsyncThunk(
  'request/addDataByTableId',
  async ({ tableID, ...payload }: any, thunkAPI) => {
    return await axios.post(`${process.env.REACT_APP_SERVER}/data/${tableID}`, payload, {
      withCredentials: true,
    }).catch(catchError).then(() => thunkAPI.dispatch(getDataByTableId(tableID)));
  }
);

export const editDataByTableIdAndRowId = createAsyncThunk(
  'request/editDataByTableIdAndRowId',
  async ({ tableID, rowID, ...payload }: any, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_SERVER}/data/${tableID}/update/${rowID}`, payload, {
      withCredentials: true,
    }).catch(catchError).then(() => thunkAPI.dispatch(getDataByTableId(tableID)));
  }
);

export const deleteDataByTableIdAndRowId = createAsyncThunk(
  'request/deleteDataByTableIdAndRowId',
  async ({ tableID, rowID }: any, thunkAPI) => {
    return await axios.delete(`${process.env.REACT_APP_SERVER}/data/${tableID}/delete/${rowID}`, {
      withCredentials: true,
    }).catch(catchError).then(() => thunkAPI.dispatch(getDataByTableId(tableID)));
  }
);

export const nextStatusByTableIdAndRowId = createAsyncThunk(
  'request/nextStatusByTableIdAndRowId',
  async ({ tableID, rowID }: any, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_SERVER}/data/${tableID}/next-status/${rowID}`, {}, {
      withCredentials: true,
    }).catch(catchError).then(() => thunkAPI.dispatch(getDataByTableId(tableID)));
  }
);

export const prevStatusByTableIdAndRowId = createAsyncThunk(
  'request/prevStatusByTableIdAndRowId',
  async ({ tableID, rowID }: any, thunkAPI) => {
    return await axios.put(`${process.env.REACT_APP_SERVER}/data/${tableID}/prev-status/${rowID}`, {}, {
      withCredentials: true,
    }).catch(catchError).then(() => thunkAPI.dispatch(getDataByTableId(tableID)));
  }
);

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDataByTableId.pending, (state, action) => {        
        return { ...state, loading: { ...state.loading, [action.payload as unknown as string]: true }}
      })
      .addCase(getDataByTableId.fulfilled, (state, action) => {
        return { ...state, loading: {}, data: action.payload }
      })
      .addCase(getDataByTableId.rejected, (state) => {
        return { ...state, loading: {} }
      })
  },
});

export const selectData = (state: RootState) => state.data.data;

export default dataSlice.reducer;
