import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { clearMessage, selectCommon } from "store/common";

const Notification = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(selectCommon);
  const handleClose = () => {
    dispatch(clearMessage());
  }
  return (
    <Snackbar open={!!error} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity="error">{error}</Alert>
    </Snackbar>
  );
}

export default Notification;