import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50px',
    alignItems: 'flex-end',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    '& button': {
      marginBottom: '6px',
    },
  },
});
