import { useEffect } from 'react';

import Table from 'components/Table';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { DataI, getDataByTableId, selectData } from 'store/data';
import { ColumnI, TableActionI, TableI } from 'types/project';
import { SearchOptionType } from 'components/Search';

interface DataTableProps {
  table: TableI;
  actions?: TableActionI[];
  filters?: SearchOptionType[];
}

const DataTable = (props: DataTableProps) => {
  const dispatch = useAppDispatch();
  const data: Record<string, DataI[]> = useAppSelector(selectData);
  const columns = props.table.columns.filter(({ show }: ColumnI) => show) || [];
  const tableId = props.table?.id;
  const rows = data?.[tableId] ? data?.[tableId]
    .filter((row) => {
      if (props.filters?.length) {
        return props.filters.reduce((acc, option) => {
          if (!acc) {
            return false;
          }
          return row[option.type].includes(option.key)
        }, true);
      }
     return true;
    })
    .map((row: Record<string, string>) => {
      const status = props.table.statuses.find((status: Record<string, string>) => status.id === row.status);
      return { ...row, status: status || row.status };
    }) : [];

  useEffect(() => {
    dispatch(getDataByTableId(props.table.id));
  }, [dispatch, props.table.id]);

  return (
    <Table
      columns={columns}
      rows={rows}
      table={props.table}
      actions={props.actions}
    />
  );
}

export default DataTable;