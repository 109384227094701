import Table from 'components/Table';
import { TableActionI } from 'types/project';

interface DataTableProps {
  data: Record<string, string>[];
  setSelectedRow: (row: Record<string, string>) => void;
  handleDeleteModalOpen: () => void;
}

const COLUMNS = [
  {
    key: `name`,
    name: `Замовник`,
    show: true,
  },
];

const DataTable = (props: DataTableProps) => {
  const actions: TableActionI[] = [
    {
      name: 'Видалити',
      color: 'error',
      handler: (row: Record<string, string>) => {
        props.setSelectedRow(row);
        props.handleDeleteModalOpen();
      },
    },
  ];

  return <Table columns={COLUMNS} rows={props.data} actions={actions} />;
};

export default DataTable;
