import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/material';

import Account from 'pages/account';
import CreateProject from 'pages/createProject';
import Project from 'pages/project';
import Members from 'pages/members';
import Customers from 'pages/customers';
import Archive from 'pages/archive';
import ProjectSettings from 'pages/projectSettings';
import Login from 'pages/login';
import Notification from 'components/Notification';
import Loading from 'components/Loading';
import SideBar from 'components/SideBar';
import Header from 'components/Header';

const styles = createUseStyles({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    display: 'flex',
    height: 'calc(100% - 100px)',
  },
});

function App() {
  const classes = styles();

  return (
    <>
      <Loading />
      <Notification />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/"
            element={
              <Box className={classes.root}>
                <Routes>
                  <Route index element={<Login />} />
                </Routes>
              </Box>
            }
          />
          <Route
            path="/panel/*"
            element={
              <Box className={classes.root}>
                <Header />
                <Box className={classes.page}>
                  <SideBar />
                  <Routes>
                    <Route path="/" element={<Account />} />
                    <Route path="projects/create" element={<CreateProject />} />
                    <Route path="projects/:id" element={<Project />} />
                    <Route path="members/:id" element={<Members />} />
                    <Route path="customers/:id" element={<Customers />} />
                    <Route path="archive/:id" element={<Archive />} />
                    <Route
                      path="projects-settings/:id"
                      element={<ProjectSettings />}
                    />
                  </Routes>
                </Box>
              </Box>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
