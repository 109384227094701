import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  deleteMemberByTableIdAndUserId,
  getProjectDetails,
  selectProjectDetails,
} from 'store/projects';
import DataTable from './DataTable';
import AddMemberModal from './AddMemberModal';
import ConfirmModal from 'components/ConfirmModal';
import Layout from 'components/Layout';

function Members() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const projectDetails = useAppSelector(selectProjectDetails);
  const table = projectDetails?.tables[0];
  const members = table?.members || [];
  const roles = table?.roles;
  const tableID = table?.id;

  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const handleAddMemberModalOpen = () => setAddMemberModalOpen(true);
  const handleAddMemberModalClose = () => setAddMemberModalOpen(false);

  const [selectedRow, setSelectedRow] = useState<Record<string, string> | null>(
    null,
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);
  const handleDelete = () => {
    if (tableID && selectedRow?.id && id) {
      dispatch(
        deleteMemberByTableIdAndUserId({
          tableID,
          userID: selectedRow?.id,
          projectID: id,
        }),
      );
      handleDeleteModalClose();
    }
  };

  useEffect(() => {
    dispatch(getProjectDetails(id as string));
  }, [dispatch, id]);

  return (
    <>
      <Layout title={`Проєкт ${projectDetails?.name}`}>
        <Button
          variant="contained"
          onClick={handleAddMemberModalOpen}
          endIcon={<AddIcon />}
        >
          Додати
        </Button>

        {projectDetails?.tables?.[0]?.members && table && (
          <DataTable
            data={members}
            table={table}
            setSelectedRow={setSelectedRow}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        )}
      </Layout>
      {projectDetails?.tables[0].id && roles && addMemberModalOpen ? (
        <AddMemberModal
          open={addMemberModalOpen}
          tableID={projectDetails?.tables[0].id}
          projectID={projectDetails?.id}
          members={members}
          roles={roles}
          handleClose={handleAddMemberModalClose}
        />
      ) : null}
      {deleteModalOpen && (
        <ConfirmModal
          open={deleteModalOpen}
          title="Чи впевнені ви що хочете видалити запис?"
          handleClose={handleDeleteModalClose}
          handleConfirm={handleDelete}
        />
      )}
    </>
  );
}

export default Members;
