import {createUseStyles} from 'react-jss';

// TODO move styles to common component
export default createUseStyles({
  buttonGroup: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
    gap: 10,
  },
})