import React, { useRef } from 'react';
import dayjs from 'dayjs';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Alert, Link } from '@mui/material';

import { ColumnI, TableActionI, TableI } from 'types/project';
import TableActions from 'components/TableActions';
import useStyles from './style';

interface TableComponentProps {
  columns: ColumnI[];
  rows: Record<string, any>[];
  table?: TableI;
  actions?: TableActionI[];
}

const TableComponent = (props: TableComponentProps) => {
  const classes = useStyles();
  const tableRef = useRef<HTMLTableElement>(null);
  const isEmptyTable = props.rows.length === 0;
  const columnsNames = props.columns.map(({ key, name, type }) => {
    if (type === 'number') {
      const sum = props.rows.reduce((acc, row) => {
        return acc + row[key];
      }, 0);
      return { name, comment: sum };
    }
    return { name };
  });

  if (props.actions) {
    columnsNames.push({ name: '' });
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '80%' }}>
        <Table stickyHeader aria-label="sticky table" ref={tableRef}>
          <TableHead>
            <TableRow>
              {columnsNames.map(({ name, comment }) => (
                <TableCell key={name + comment}>
                  {name}
                  {comment ? <span className={classes.columnTitleComment}> | {comment}</span> : ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmptyTable ? (
              <TableRow>
                <TableCell colSpan={props.columns.length + (props.actions ? 1 : 0)}>
                  <Alert severity="warning">
                    Таблиця порожня.
                  </Alert>
                </TableCell>
              </TableRow>
            ) : (
              props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {props.columns.map((column: ColumnI) => {
                        let value =
                          typeof row[column.key] === 'object'
                            ? row[column.key]?.name
                            : row[column.key];

                        if (column.type?.includes('member')) {
                          const member = props.table?.members.find(
                            ({ id }) => id === row[column.key],
                          );
                          value = member?.name || member?.email || 'не знайдено';
                        }

                        if (column.type === 'customer') {
                          const customer = props.table?.customers.find(
                            ({ user }) => user === row[column.key],
                          );
                          value = customer?.name || 'не знайдено';
                        }

                        if (column.type === 'role') {
                          const role = props.table?.roles[row[column.key]];
                          value = role?.name;
                        }

                        if (column.type === 'date') {
                          value = dayjs(row[column.key]).locale('uk').format('DD.MM HH:mm');
                        }

                        return (
                          <TableCell
                            key={column.key}
                            align={column.type === 'number' ? 'center' : 'left'}
                          >
                            {value?.indexOf?.('https://') === 0 ? (
                              <Link
                                href={value}
                                underline="hover"
                                target="_blank"
                              >
                                Посилання
                              </Link>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                      {props.actions ? (
                        <TableActions actions={props.actions} row={row} />
                      ) : null}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
  );
};

export default TableComponent;
