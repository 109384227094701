import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './index';
import axios from 'axios';
import { ProjectI, ProjectsDetailsI } from 'types/project';
import { catchError } from './common';

export interface ProjectsI {
  projects: ProjectI[];
  projectDetails: null | ProjectsDetailsI;
}

const initialState: ProjectsI = {
  projects: [],
  projectDetails: null,
};

export const getProjects = createAsyncThunk('request/getProjects', async () => {
  const { data } = await axios
    .get(`${process.env.REACT_APP_SERVER}/projects`, {
      withCredentials: true,
    })
    .catch(catchError);

  return data;
});

export const getProjectDetails = createAsyncThunk(
  'request/getProjectDetails',
  async (id: string) => {
    const { data } = await axios
      .get(`${process.env.REACT_APP_SERVER}/projects/${id}`, {
        withCredentials: true,
      })
      .catch(catchError);

    return data;
  },
);

export const createProject = createAsyncThunk(
  'request/createProject',
  async (
    { name, layout = 'TASKS_MANAGEMENT' }: { name: string; layout: string },
    thunkAPI,
  ) => {
    const { data } = await axios
      .post(
        `${process.env.REACT_APP_SERVER}/projects/create`,
        {
          name,
          layout,
        },
        { withCredentials: true },
      )
      .catch(catchError);
    await thunkAPI.dispatch(getProjects());
    return data;
  },
);

export const addMembersByTableId = createAsyncThunk(
  'request/addMembersByTableId',
  async (
    {
      email,
      role,
      tableID,
      projectID,
    }: { email: string; role: string; tableID: string; projectID: string },
    thunkAPI,
  ) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER}/projects/addMembersByTableId/${tableID}`,
        {
          email,
          role,
        },
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjectDetails(projectID)));
  },
);

export const deleteMemberByTableIdAndUserId = createAsyncThunk(
  'request/deleteMemberByTableIdAndUserId',
  async (
    {
      tableID,
      userID,
      projectID,
    }: { tableID: string; userID: string; projectID: string },
    thunkAPI,
  ) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_SERVER}/projects/deleteMemberByTableIdAndUserId/${tableID}/${userID}`,
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjectDetails(projectID)));
  },
);

export const addCustomerByTableId = createAsyncThunk(
  'request/addCustomerByTableId',
  async (
    {
      name,
      tableID,
      projectID,
    }: { name: string; tableID: string; projectID: string },
    thunkAPI,
  ) => {
    return await axios
      .post(
        `${process.env.REACT_APP_SERVER}/projects/addCustomerByTableId/${tableID}`,
        {
          name,
        },
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjectDetails(projectID)));
  },
);

export const deleteCustomerByTableIdAndCustomerId = createAsyncThunk(
  'request/deleteCustomerByTableIdAndCustomerId',
  async (
    {
      tableID,
      customerID,
      projectID,
    }: { tableID: string; customerID: string; projectID: string },
    thunkAPI,
  ) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_SERVER}/projects/deleteCustomerByTableIdAndCustomerId/${tableID}/${customerID}`,
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjectDetails(projectID)));
  },
);

export const deleteProjectById = createAsyncThunk(
  'request/deleteProjectById',
  async (
    {
      projectID,
    }: { projectID: string },
    thunkAPI,
  ) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_SERVER}/projects/${projectID}`,
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjects()));
  },
);

export const renameProjectById = createAsyncThunk(
  'request/renameProjectById',
  async (
    {
      projectID,
      name,
    }: { projectID: string; name: string },
    thunkAPI,
  ) => {
    return await axios
      .put(
        `${process.env.REACT_APP_SERVER}/projects/${projectID}`,
        { name },
        { withCredentials: true },
      )
      .catch(catchError)
      .then(() => thunkAPI.dispatch(getProjects()))
      .then(() => thunkAPI.dispatch(getProjectDetails(projectID)));
  },
);


export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProjects.fulfilled, (state, action) => {
        return { ...state, projects: action.payload };
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        return { ...state, projectDetails: action.payload };
      });
  },
});

export const selectProjects = (state: RootState) => state.projects.projects;
export const selectProjectDetails = (state: RootState) =>
  state.projects.projectDetails;

export default projectsSlice.reducer;
