import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/store';
import { getProjectDetails, selectProjectDetails } from 'store/projects';
import {
  ProjectsDetailsI,
  TableI,
} from 'types/project';
import { UserI, getProfile, selectUser } from 'store/user';
import Layout from 'components/Layout';
import DataTable from './DataTable';

function Archive() {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const projectDetails: ProjectsDetailsI | null =
    useAppSelector(selectProjectDetails);
  const user: UserI | null = useAppSelector(selectUser);
  const table: TableI | undefined = projectDetails?.tables[0];

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!user) {
      dispatch(getProfile());
    }
  }, [dispatch, user]);

  return (
    <>
      <Layout title={`Архів проєкту ${projectDetails?.name}`}>
        {table && (
          <DataTable
            table={table}
          />
        )}
      </Layout>
    </>
  );
}

export default Archive;
