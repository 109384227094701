import { TextField, Select, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ColumnI } from 'types/project';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface FormFieldProps {
  formik: any;
  column: ColumnI;
  members?: Record<string, string>[];
  customers?: Record<string, string>[];
  statuses?: Record<string, string>[];
};

function FormField(props: FormFieldProps) {
  if (props.column.type === 'status') {
    return (
      <>
        <InputLabel id={props.column.key} error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}>
          {props.column.name}
        </InputLabel>
        <Select
          fullWidth
          labelId={props.column.key}
          id={props.column.key}
          variant="standard"
          value={props.formik.values[props.column.key] || 'none'}
          label={props.column.name}
          onChange={(e) => props.formik.setFieldValue(props.column.key, e.target.value)}
          error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}
        >
          {!props.formik.values[props.column.key] && <MenuItem value='none'>Не вибрано</MenuItem>}
          {props.statuses && props.statuses.map(({ name, id }) => (
            <MenuItem key={id} value={id}>{name}</MenuItem>
          ))}
        </Select>
        {props.formik.touched[props.column.key] && props.formik.errors[props.column.key] && (
          <FormHelperText error>{props.formik.errors[props.column.key]}</FormHelperText>
        )}
      </>
    )
  }

  if (props.column.type?.includes('member:')) {
    return (
      <>
        <InputLabel id={props.column.key} error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}>
          {props.column.name}
        </InputLabel>
        <Select
          fullWidth
          labelId={props.column.key}
          id={props.column.key}
          variant="standard"
          value={props.formik.values[props.column.key] || 'none'}
          label={props.column.name}
          onChange={(e) => props.formik.setFieldValue(props.column.key, e.target.value)}
          error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}
        >
          {!props.formik.values[props.column.key] && <MenuItem value='none'>Не вибрано</MenuItem>}
          {props.members && props.members.filter(({ role }) => props.column?.type?.includes(role)).map(({ email, name, id }) => (
            <MenuItem key={id} value={id}>{name || email}</MenuItem>
          ))}
        </Select>
        {props.formik.touched[props.column.key] && props.formik.errors[props.column.key] && (
          <FormHelperText error>{props.formik.errors[props.column.key]}</FormHelperText>
        )}
      </>
    )
  }

  if (props.column.type?.includes('customer')) {
    return (
      <>
        <InputLabel id={props.column.key} error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}>
          {props.column.name}
        </InputLabel>
        <Select
          fullWidth
          labelId={props.column.key}
          id={props.column.key}
          variant="standard"
          value={props.formik.values[props.column.key] || 'none'}
          label={props.column.name}
          onChange={(e) => props.formik.setFieldValue(props.column.key, e.target.value)}
          error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}
        >
          {!props.formik.values[props.column.key] && <MenuItem value='none'>Не вибрано</MenuItem>}
          {props.customers?.map(({ name, user }) => (
            <MenuItem key={user} value={user}>{name}</MenuItem>
          ))}
        </Select>
        {props.formik.touched[props.column.key] && props.formik.errors[props.column.key] && (
          <FormHelperText error>{props.formik.errors[props.column.key]}</FormHelperText>
        )}
      </>
    )
  }

  if (props.column.type === 'date') {
    const setDate = (value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
      if (value?.isValid() && !context.validationError) {
        props.formik.handleChange({target: { id: props.column.key, value: value?.format('YYYY-MM-DD HH:mm:ss') }})
      } else {
        props.formik.setErrors({ ...props.formik.errors, [props.column.key]: 'Невірна дата' });
      }
    }
    
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel id={props.column.key} error={Boolean(props.formik.errors[props.column.key])}>
          {props.column.name}
        </InputLabel>
        <DateTimePicker
          ampm={false}
          value={dayjs(props.formik.values[props.column.key])}
          onChange={setDate}
          format='DD.MM.YYYY HH:mm'
        />
        {props.formik.errors[props.column.key] && (
          <FormHelperText error>{props.formik.errors[props.column.key]}</FormHelperText>
        )}
      </LocalizationProvider>
    )
  }

  return (
    <TextField
      fullWidth
      id={props.column.key}
      name={props.column.key}
      label={props.column.name}
      variant="standard"
      value={props.formik.values[props.column.key]}
      onChange={props.formik.handleChange}
      error={props.formik.touched[props.column.key] && Boolean(props.formik.errors[props.column.key])}
      helperText={props.formik.touched[props.column.key] && props.formik.errors[props.column.key]}
    />
  );
}

export default FormField;
