import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  addCustomerByTableId,
  addMembersByTableId,
  createProject,
  deleteCustomerByTableIdAndCustomerId,
  deleteMemberByTableIdAndUserId,
  deleteProjectById,
  getProjectDetails,
  getProjects,
  renameProjectById,
} from 'store/projects';
import {
  authLogin,
  getProfile,
  logout,
} from 'store/user';
import { addDataByTableId,
  deleteDataByTableIdAndRowId,
  editDataByTableIdAndRowId,
  getDataByTableId,
  nextStatusByTableIdAndRowId,
  prevStatusByTableIdAndRowId,
} from './data';
import { AxiosError } from 'axios';
import { getArchiveByTableId } from './archive';

export interface CommonStateI {
  loading: number;
  error: string | null;
}

const initialState: CommonStateI = {
  loading: 0,
  error: null,
};

export const catchError = (error: AxiosError) => {
  if ( error.response?.status === 401) {
    window.location.href = '/';
  }

  throw error;
}

export const projectsSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearMessage(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // user
      .addCase(getProfile.pending, (state) => {
        state.loading += 1;
      })
      .addCase(getProfile.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при отриманні профайлу'
      })

      .addCase(authLogin.pending, (state) => {
        state.loading += 1;
      })
      .addCase(authLogin.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(authLogin.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при авторізації'
      })

      .addCase(logout.pending, (state) => {
        state.loading += 1;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(logout.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при виході'
      })

      // project
      .addCase(getProjects.pending, (state) => {
        state.loading += 1;
      })
      .addCase(getProjects.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(getProjects.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при отриманні проєктів'
      })

      .addCase(getProjectDetails.pending, (state) => {
        state.loading += 1;
      })
      .addCase(getProjectDetails.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(getProjectDetails.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при отриманні даних проєкта'
      })

      .addCase(createProject.pending, (state) => {
        state.loading += 1;
      })
      .addCase(createProject.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(createProject.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при створенні проєкта'
      })

      .addCase(addMembersByTableId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(addMembersByTableId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(addMembersByTableId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при додаванні користувача'
      })

      .addCase(deleteMemberByTableIdAndUserId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(deleteMemberByTableIdAndUserId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(deleteMemberByTableIdAndUserId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при видаленні користувача'
      })

      .addCase(addCustomerByTableId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(addCustomerByTableId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(addCustomerByTableId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при додаванні замовника'
      })

      .addCase(deleteCustomerByTableIdAndCustomerId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(deleteCustomerByTableIdAndCustomerId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(deleteCustomerByTableIdAndCustomerId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при видаленні замовника'
      })

      .addCase(deleteProjectById.pending, (state) => {
        state.loading += 1;
      })
      .addCase(deleteProjectById.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(deleteProjectById.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при видаленні проєкту'
      })

      .addCase(renameProjectById.pending, (state) => {
        state.loading += 1;
      })
      .addCase(renameProjectById.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(renameProjectById.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при перейменуванні проєкту'
      })


      // data
      .addCase(getDataByTableId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(getDataByTableId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(getDataByTableId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка отримання даних'
      })

      .addCase(addDataByTableId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(addDataByTableId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(addDataByTableId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при додаванні даних'
      })

      .addCase(editDataByTableIdAndRowId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(editDataByTableIdAndRowId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(editDataByTableIdAndRowId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при редагуванні даних'
      })

      .addCase(deleteDataByTableIdAndRowId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(deleteDataByTableIdAndRowId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(deleteDataByTableIdAndRowId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при видаленні даних'
      })

      .addCase(nextStatusByTableIdAndRowId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(nextStatusByTableIdAndRowId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(nextStatusByTableIdAndRowId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при зміненні статусу'
      })

      .addCase(prevStatusByTableIdAndRowId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(prevStatusByTableIdAndRowId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(prevStatusByTableIdAndRowId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка при зміненні статусу'
      })


      // archive
      .addCase(getArchiveByTableId.pending, (state) => {
        state.loading += 1;
      })
      .addCase(getArchiveByTableId.fulfilled, (state) => {
        state.loading -= 1;
      })
      .addCase(getArchiveByTableId.rejected, (state) => {
        state.loading -= 1;
        state.error = 'Помилка отримання архіву'
      })
  },
});

export const selectCommon = (state: RootState) => state.common;
export const { clearMessage } = projectsSlice.actions;
export default projectsSlice.reducer;
