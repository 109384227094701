import {
  Modal as MuiModal,
  ModalProps,
  Paper,
  Typography,
} from '@mui/material';
import useStyles from './style';

const Modal = (props: ModalProps) => {
  const classes = useStyles();

  return (
    <MuiModal open={props.open} className={props.className} onClose={props.onClose}>
      <Paper className={classes.paper}>
        <Typography variant="h6">{props.title}</Typography>
        {props.children}
      </Paper>
    </MuiModal>
  );
};

export default Modal;
