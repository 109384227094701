import { createUseStyles } from 'react-jss';

export default createUseStyles({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '10px',
  },
  buttonSuccess: {
    marginRight: '5px',
  },
});
