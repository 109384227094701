import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonReducer from 'store/common';
import userReducer from 'store/user';
import projectsReducer from 'store/projects';
import dataReducer from 'store/data';
import archiveReducer from 'store/archive';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    user: userReducer,
    projects: projectsReducer,
    data: dataReducer,
    archive: archiveReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
