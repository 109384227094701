import {createUseStyles} from 'react-jss';

export default createUseStyles({
  content: {
    width: '100%',
    height: '100%',
    margin: '20px 0 20px 20px',
    padding: '20px 30px',
    boxSizing: 'border-box',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: 'rgb(205, 230, 252)',
    overflow: 'auto',
  },
  title: {
    marginBottom: 20,
  }
})
