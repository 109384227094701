import {createUseStyles} from 'react-jss';

export default createUseStyles({
  tablePanel: {
    width: '100%',
    minHeight: 55,
    display: 'flex',
    justifyContent: 'space-between',
  }
})
